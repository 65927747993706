import { call, put, takeLatest } from "redux-saga/effects";
import {
  advertisementDataRequest,
  getAdvertisementDataSuccess,
  getAdvertisementDataFailure,
} from "../slice/advertisementSlice";
// import api from "../config/api";
import axios from "axios";

const API_URL = "./json/advertisementData.json";

function* getAdvertisementData(): any {
  try {
    const response: any = yield call(axios.get, API_URL, { baseURL: "/" });
    yield put(getAdvertisementDataSuccess(response.data));
  } catch (error: any) {
    yield put(getAdvertisementDataFailure(error.message));
  }
}

export function* watchAdvertisementData(): any {
  yield takeLatest(advertisementDataRequest.type, getAdvertisementData);
}
