import React, { useState } from "react";
import { t } from "i18next";
import { ResetUnlockBanner } from "../../../pages/reset-unlock/banner";
import styles from "./parent-consent.module.scss";
import TGInput from "../../../../../shared/tg-input";
import TGButtonVariants from "../../../../../shared/tg-button-variants";
import { useLocation } from "react-router-dom";
import {
  ROPEnrollmentData,
  ROPParentConsentErrorData,
} from "../../../models/models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";

const ParentConsent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RopSIgnupResponse = useSelector(
    (state: RootState) => state?.osciReducer?.ropSin
  );

  const location = useLocation();
  const { enrollmentData } = location.state || {};
  const name = `${enrollmentData.firstName} ${enrollmentData.lastName}`;

  const [parentErrors, setParentErrors] = useState<
    Partial<ROPParentConsentErrorData>
  >({});
  // const [errors, setErrors] = useState<Partial<ROPEnrollmentData>>({});

  const [isNameError, setIsNameError] = useState(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setConsentFormData({ ...consentFormData, [name]: value });
    setParentErrors({ ...parentErrors, [name]: "" });
    console.log("enrollment form data of consent form", consentFormData);
  };
  const handleBack = () => {};
  const handleBackSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    navigate(`/${i18n.language}/signup`, { state: { enrollmentData } });
  };
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const updatedFormDataArray = [consentFormData];
    let consentParentFormData: any = updatedFormDataArray.map((x: any) => {
      return {
        consentFlag: x.consentFlag,
        consentParentFirstName: x.consentParentFirstName,
        consentParentLastName: x.consentParentLastName,
      };
    });
    console.log("Continue button click", consentParentFormData);
    const newConsentErrors: Partial<ROPParentConsentErrorData> = {};
    console.log(
      "Parent first and last name: ",
      consentFormData.consentParentFirstName,
      consentFormData.consentParentLastName
    );

    Object.entries(consentParentFormData[0]).forEach(([key, value]) => {
      console.log("key", key, value);
      if (typeof value === "string" && !value) {
        console.log("Debugging");
        newConsentErrors[key as keyof ROPParentConsentErrorData] =
          getFieldLabel(key as keyof ROPParentConsentErrorData);
      }
    });
    setParentErrors(newConsentErrors);
    console.log("checking errors : ", newConsentErrors);
    if (Object.keys(newConsentErrors).length === 0) {
      console.log("If condition entering");
      enrollmentData.consentParentFirstName =
        consentFormData.consentParentFirstName;
      enrollmentData.consentParentLastName =
        consentFormData.consentParentLastName;
      enrollmentData.consentFlag = true;
      console.log("New data checking", enrollmentData);
      navigate(`/${i18n.language}/enrolment/parentconsent/success`, {
        state: { enrollmentData },
      });
    }
  };

  const getFieldLabel = (fieldName: keyof ROPParentConsentErrorData) => {
    const parentlabels: { [key in keyof ROPParentConsentErrorData]: string } = {
      consentParentFirstName: t("error_reset_first_name_required"),
      consentParentLastName: t("error_reset_last_name_required"),
      consentFlag: t("error_reset_last_name_required"),

      // title: "",
      // firstName: "",
      // lastName: "",
      // dob: "",
      // addressLine1: "",
      // country: "",
      // countryCode: "",
      // contactNumber: "",
      // city: "",
      // postalCode: "",
      // email: "",
      // confirmEmail: "",
      // createPassword: "",
      // confirmPassword: "",
      // termsConditions: "",
    };
    return parentlabels[fieldName];
  };

  const [consentFormData, setConsentFormData] = useState<ROPEnrollmentData>({
    title: "",
    firstName: "",
    lastName: "",
    dob: "",
    languagePreference: "E",
    addressLine1: "",
    addressLine2: "",
    country: "",
    countryCode: "",
    contactNumber: "",
    city: "",
    state: "",
    postalCode: "",
    email: "",
    confirmEmail: "",
    createPassword: "",
    confirmPassword: "",
    referringMemberID: "",
    seatPreference: "NNN",
    mealPreference: "NNN",
    termsConditions: "",
    ropNewsCheck: "",
    thirdPartyCheck: "",
    consentFlag: false,
    consentParentFirstName: "",
    consentParentLastName: "",
  });
  return (
    <ResetUnlockBanner
      imageText={t("label_enrolment_consent_signup")}
      descriptionText1={t("label_enrolment_consent_description1")}
      descriptionText2={t("label_enrolment_consent_description2")}
    >
      <div className={styles.header}>
        {t("label_enrolment_consent_parent_title")}
      </div>
      <div className={styles.label}>
        {t("label_enrolment_consent_parent_description")}
      </div>
      <div className={styles.header}>
        {t("label_enrolment_consent_member_name")}
      </div>
      <div className={styles.input}>
        <TGInput
          label={t("label_enrolment_consent_full_name")}
          onChange={handleChange}
          value={name}
          disables
          validation="name"
        />
      </div>
      <div className={styles.header}>
        {t("label_enrolment_consent_parent_info_title")}
      </div>
      <div className={styles.input}>
        <TGInput
          label={t("label_join_nowlabel_reset_first_name")}
          onChange={handleChange}
          value={consentFormData.consentParentFirstName}
          name="consentParentFirstName"
          isError={!!parentErrors.consentParentFirstName}
          errorLabel={parentErrors.consentParentFirstName}
          validation="name"
        />

        <TGInput
          label={t("label_manage_last_name")}
          onChange={handleChange}
          value={consentFormData.consentParentLastName}
          name="consentParentLastName"
          isError={!!parentErrors.consentParentLastName}
          errorLabel={parentErrors.consentParentLastName}
          validation="name"
        />
      </div>
      <div className={styles.buttonContainer}>
        <TGButtonVariants
          label={t("button_consent_back")}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="30px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColor="#F5EFFF"
          padding="16px"
          textColor="#684B9B"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="160%"
          borderDisabled="0.791px solid #E3D4FE"
          bgColorDisabled="#E3D4FE"
          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColorHover="#684b9b"
          borderHover="0.791px solid #E3D4FE"
          boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          width="10.6875rem"
          height="3.81rem"
          onClick={handleBackSubmit}
        />
        <TGButtonVariants
          label={t("button_reset_continue")}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="30px"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColor="#684B9B"
          padding="16px"
          textColor="#FFFFFF"
          fontFamily="Inter"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="160%"
          borderDisabled="0.791px solid #E3D4FE"
          bgColorDisabled="#E3D4FE"
          boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          bgColorHover="#684b9b"
          borderHover="0.791px solid #E3D4FE"
          boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
          width="10.6875rem"
          height="3.81rem"
          onClick={handleSubmit}
        />
      </div>
    </ResetUnlockBanner>
  );
};

export default ParentConsent;
